<template>
  <div>
    <b-row
      v-for="(orderTemplate, index) in orderTemplates"
      :key="orderTemplate.id"
    >
      <b-card
        class="card-style"
        style="width: 100%"
        @click="toggleExpandCollapse(index)"
      >
        <b-row class="align-items-center">
          <!-- <b-col cols="1">test</b-col> -->
          <b-col :class="{ 'mt-2': windowWidth < 768 }" lg="3" md="3" sm="12">
            <span
              v-if="windowWidth < 768"
              class="text-primary font-weight-bold title-text"
            >
              Name
              <br />
            </span>
            {{ orderTemplate.templateName }}
          </b-col>
          <b-col :class="{ 'mt-2': windowWidth < 768 }" lg="3" md="3" sm="12">
            <span
              v-if="windowWidth < 768"
              class="text-primary font-weight-bold title-text"
            >
              Bill To
              <br />
            </span>
            {{
              orderTemplate.billTo && orderTemplate.billTo.name
                ? orderTemplate.billTo.name
                : ""
            }}
          </b-col>

          <b-col :class="{ 'mt-2': windowWidth < 768 }" lg="3" md="3" sm="12">
            <span v-if="windowWidth < 768" class="text-primary font-weight-bold title-text">
              Delivery Site
              <br />
            </span>
            <div class="text-wrap text-break" style="max-width: 200px">
              {{
                orderTemplate.details && orderTemplate.details.length
                  ? getDeliveryAddress(orderTemplate.details[0].deliverySite)
                  : ""
              }}
            </div>
          </b-col>
          <b-col :class="{ 'mt-3 text-center': windowWidth < 768 }"
                 lg="2"
                 md="2"
                 sm="12">
           
            <a id="btnCreateOrder"
                    variant="outline-primary"
                    class="btn btn-outline-primary"
                     @click="CreateOrderFromTemplate(orderTemplate)"
                     @contextmenu.prevent.stop="CreateOrderFromTemplateFromContextMenu(orderTemplate)"
                    v-if="canCreateAdHocOrders || canCreateOrdersFromTemplatesOnly">
              Create Order
            </a>
          </b-col>
          <b-col
            lg="1"
            md="1"
            sm="6"
            v-if="windowWidth >= 768"
            class="text-right"
            cols="1"
          >
            <b-button variant="link">
              <i v-if="!ItemState[index]" class="fa fa-caret-down fa-lg"></i>
              <i v-if="ItemState[index]" class="fa fa-caret-left fa-lg"></i>
            </b-button>
          </b-col>
        </b-row>
        <b-collapse
          :id="'orderTemplate' + index"
          :visible="ItemState[index] !== undefined ? ItemState[index] : true"
        >
          <b-row class="mt-3">
            <b-col>
              <h4 class="text-primary">Products</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="windowWidth >= 768">
              <b-table
                id="tblOrderTemplateDetails"
                :items="orderTemplate.details"
                :fields="
                  orderTemplate.division == 'SPG' ? SPGfields : FDGfields
                "
                :responsive="true"
                :small="true"
              >
                <template v-slot:cell(shipper)="row">{{
                  row.value.id && row.value.id.trim() != "CALLDIS"
                    ? row.value.name
                    : "N/A"
                }}</template>
                <template
                  v-slot:cell(supplier)="row"
                  :visible="orderTemplate.division != 'SPG'"
                  >{{
                    row.value.id && row.value.id.trim() != "CALLDIS"
                      ? row.value.name
                      : "N/A"
                  }}</template
                >
                <template
                  v-slot:cell(accountOf)="row"
                  :visible="orderTemplate.division != 'SPG'"
                  >{{ row.value ? row.value.name : "N/A" }}</template
                >
                <template v-slot:cell(product)="row">{{
                  row.value ? row.value.name : "N/A"
                }}</template>
                <template v-slot:cell(deliverySite)="row">
                  <div class="text-wrap text-break" style="max-width: 200px">                 
                    {{ getDeliveryAddress(row.value, "N/A") }}
                  </div>
                </template>
                <template v-slot:cell(amount)="row">{{
                  buildQuantityString(row)
                }}</template>
              </b-table>
            </b-col>
            <b-col v-if="windowWidth < 768">
              <MobileProductCards :products="orderTemplate.details" />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              lg="6"
              md="6"
              sm="12"
              class="mt-3"
              v-if="orderTemplate.po != null && orderTemplate.po != ''"
            >
              <span class="text-primary font-weight-bold"
                >Special Instructions:</span
              >
              <br />
              {{ orderTemplate.po }}
            </b-col>
            <b-col
              lg="6"
              md="6"
              sm="12"
              class="mt-3"
              v-if="orderTemplate.notes != null && orderTemplate.notes != ''"
            >
              <span class="text-primary font-weight-bold"
                >Additional Notes & Remarks:</span
              >
              <br />
              {{ orderTemplate.notes }}
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col>
              <b-button
                id="btnDeleteTemplate"
                variant="link"
                class="text-danger"
                @click="deleteOrderTemplate(orderTemplate.templateId)"
                v-if="canDeleteCustomerTemplates"
                >Delete Template</b-button
              >
            </b-col>
            <b-col class="text-right">
              <b-button
                id="btnEditTemplate"
                v-if="canModifyCustomerTemplates || canEditCustomerTemplates"
                @click="editOrderTender(orderTemplate.templateId)"
                @contextmenu.prevent.stop="editOrderTenderFromContextMenu(orderTemplate.templateId)"
                variant="outline-primary"
                >Edit Template</b-button
              >
            </b-col>
          </b-row>
        </b-collapse>
        <b-row v-if="windowWidth < 768">
          <b-col class="text-center text-primary">
            <i
              v-if="!ItemState[index]"
              class="fa fa-chevron-down fa-lg mt-3"
            ></i>
            <i v-if="ItemState[index]" class="fa fa-chevron-up fa-lg mt-3"></i>
          </b-col>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import MobileProductCards from "@/components/order-tender/MobileProductCard.vue";
import mixin from "@/shared/mixin";

import {
  canViewCustomerTemplates,
  canModifyCustomerTemplates,
  canCreateCustomerTemplates,
  canEditCustomerTemplates,
  canDeleteCustomerTemplates,
  canCreateAdHocOrders,
  canCreateOrdersFromTemplatesOnly,
} from "@/shared/utils/permissions";

  export default {
  name: "ViewOrderTemplateCard.vue",
  props: ["orderTemplates", "pageSize"],
  components: {
    MobileProductCards,
  },
  mixins: [mixin],
  data() {
    return {
      ItemState: new Array(this.pageSize),
      numberOfTemplates: 0,
      windowWidth: window.innerWidth,
      FDGfields: [
        "shipper",
        "supplier",
        "accountOf",
        "deliverySite",
        "product",
        "amount",
      ],
      SPGfields: ["shipper", "deliverySite", "product", "amount"],
    };
  },
  mounted: function () {
    this.ItemState.fill(true);
  },
  methods: {
    toggleExpandCollapse(index) {
      if (
        this.ItemState[index] === null ||
        this.ItemState[index] === undefined
      ) {
        this.ItemState[index] = true;
      } else {
        this.ItemState[index] = !this.ItemState[index];
      }
      this.$forceUpdate();
      return this.ItemState[index];
    },
    deleteOrderTemplate(id) {
      try {
        var requestData = {
          id: id,
          jwtToken: this.$store.state.user.jwtToken,
        };

        this.$store.dispatch("orderTemplate/deleteOrderTemplate", requestData);
      } catch (error) {
        var errorMessage = this.handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }
    },
    editOrderTender(id) {
      this.$router.push({ name: "edit-order-template", params: { id: id } });
    },
    editOrderTenderFromContextMenu(id) {
      const editOrderRoute = this.$router.resolve({ name: "edit-order-template", params: { id: id } }).href;
      window.open(editOrderRoute, '_blank');
    },
    CreateOrderFromTemplate(template) {
        this.$store.dispatch("newOrderTender/setOrderTenderData", template);
        this.$router.push({ name: "new-order-tender" });
    },
    CreateOrderFromTemplateFromContextMenu(template) {
    //   window.open("order-templates", '_blank');
      this.$store.dispatch("newOrderTender/setOrderTenderData", template);
      this.$router.push({ name: "new-order-tender" });
      
      


    },
   
    buildQuantityString(row) { 
      if (row.item.callForQuantity) {
        return "Call For Quantity";
      }
      return row.value + " " + row.item.amountUnitOfMeasure;
    },
  },
  computed: {
    canCreateAdHocOrders() {
      return canCreateAdHocOrders(this.$store);
    },
    canCreateOrdersFromTemplatesOnly() {
      return canCreateOrdersFromTemplatesOnly(this.$store);
    },
    canEditCustomerTemplates() {
      return canEditCustomerTemplates(this.$store);
    },
    canModifyCustomerTemplates() {
      return canModifyCustomerTemplates(this.$store);
    },
    canDeleteCustomerTemplates() {
      return canDeleteCustomerTemplates(this.$store);
    },
  },
};
</script>
<style scoped>
.card-style {
  border-top: #114c8e solid 2px;
  -webkit-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.35);
  box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.35);
}

.mobile-card {
  width: 100%;
  padding: 10px;
  font-size: 13px;
}

.webview-card {
  width: 100%;
  /* padding: 20px; */
}

.fixed-div {
  position: fixed;
}

.title-text {
  font-size: 16px;
}
</style>
