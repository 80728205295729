<template>
  <div>
    <b-form-group>
      <b-input-group class="has-feedback has-clear">
        <date-picker
          v-model="date"
          @dp-change="changeValue"
          :config="options"
          :placeholder="format"
          :id="id"
        ></date-picker>

        <b-input-group-text slot="append">
          <i class="fa fa-calendar"></i>
        </b-input-group-text>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
import datePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import { dateTimeFormats } from "@/shared/constants";

export default {
  props: ["selectedDate", "id", "labelText"],
  components: {
    datePicker
  },
  created() {},
  data() {
    return {
      date: this.selectedDate,
      format: dateTimeFormats.dateOnlyFormat.toLowerCase(),
      options: {
        format: dateTimeFormats.dateOnlyFormat,
        useCurrent: false,
        showClear: true,
        showClose: true
      }
    };
  },
  watch: {
    selectedDate() {
      this.date = this.selectedDate;
    }
  },
  methods: {
    changeValue: function(e) {
      var oldValue = e.date
        ? e.date.format(dateTimeFormats.dateOnlyFormat)
        : "";
      this.$emit("onChange", oldValue);
    }
  }
};
</script>

<style scoped></style>
